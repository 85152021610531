import { HoolaMessage, Feed, FeedCounts, FeedWhatsAppConversations } from '@/types/feed.types'
import { useAgentPreferencesStore } from '@/stores/useAgentPreferencesStore'
import { ContactDtoFeed, ContactTagDto } from '@/types/contact.types'
import workspace from '@/plugins/axios/workspace'
import { useSnackbarStore } from '@/stores/useSnackbarStore'
import { PhoneNumberUtils } from '@/utils/PhoneNumberUtils'
import { useWorkspaceStore } from '@/stores/useWorkspaceStore'
import { TemplateOrigin, useWhatsappTemplateStore } from '@/stores/useWhatsappTemplateStore'
import { AssignedChatFilter, FeedsFilters } from '@/types/inbox.types'
import { buildGetAllFeedsString, sortFeeds } from '@/services/inbox/inbox.helpers'
import { WhatsAppTemplateDto } from '@/types/whatsapp-template.types'
import { isSamePhoneNumber } from '@/utils/isSamePhoneNumber';
import { AgentDto } from './useAgentStore'
import { JSONContent } from '@tiptap/vue-3'
import axiosApi from '@/plugins/axios/axiosApi'

export interface AgentInboxOption { 
  filter: AssignedChatFilter, 
  emoji?: string
  id?: string 
}

export interface AgentInboxPreferencesOptions {
  title: string
  value: AgentInboxOption
}

export enum ChatStateFilter {
  OPEN = 'open',
  AUTOMATED = 'is_being_automated',
  CLOSED = 'is_closed',
}

interface State {
  feeds: Feed[]
  selectedFeedIds: string[]
  isFetching: boolean
  contacts: ContactDtoFeed[]
  counts: FeedCounts[]
  conversations: FeedWhatsAppConversations[]
  currentPhoneNumber: string | undefined
  feedsFilters: FeedsFilters,
  currentCounts: FeedAssignedOpenCounts,
  selectedTemplatePreview: WhatsAppTemplateDto | undefined,
  temporaryTemplatePreview: WhatsAppTemplateDto | undefined,
  isFilterDrawerOpen: boolean,
  selectedChatStateFilter: ChatStateFilter,
  openingFeedId: string | undefined,
  draftMessages: Record<string, JSONContent | undefined>;
}

export interface TeamIdCountMap {
  [key: string]: number
}

interface SendMessageResponse {
  message: HoolaMessage
}

interface TakeActionOnFeedResponse {
  feed: Feed
}

interface SendMessagePayload {
  body: string
  context?: { message_id: string }
}

export interface FeedAssignedOpenCounts {
  'assigned_to_me': number
  'unassigned': number
  'mentioned': number
  'all_conversations': number
  'all_temp': number
  'assigned_to_team': TeamIdCountMap
}

export type FeedAction = 'Pin' | 'Unpin' | 'Close' | 'Open' | 'Mark as Read' | 'Mark Unread'

export const useChatStore = defineStore('chat', {
  state: (): State => ({
    feeds: [],
    draftMessages: {},
    selectedFeedIds: [],
    isFetching: false,
    contacts: [],
    counts: [],
    conversations: [],
    currentPhoneNumber: undefined,
    feedsFilters: {
      metadataFilter: 'open',
      query: '',
      order: 'DESC',
    },
    currentCounts: {
      assigned_to_me: 0,
      all_conversations: 0,
      unassigned: 0,
      mentioned: 0,
      all_temp: 0,
      assigned_to_team: {}
    },
    selectedTemplatePreview: undefined,
    temporaryTemplatePreview: undefined,
    isFilterDrawerOpen: false,
    selectedChatStateFilter: ChatStateFilter.OPEN,
    openingFeedId: undefined,
  }),
  getters: {
    templatePreview(state) {
      if (state.temporaryTemplatePreview) return state.temporaryTemplatePreview;

      return state.selectedTemplatePreview;
    }
  },
  actions: {
    async fetchFeeds(feedsFilters?: Partial<FeedsFilters>) {
      const agentPreferencesStore = useAgentPreferencesStore()
      const workspaceStore = useWorkspaceStore();

      const filters = {
        ...this.feedsFilters,
        ...feedsFilters,
      };

      if (!filters.assignedFilter) {
        const defaultFilter = await agentPreferencesStore.getDefaultInboxFilter();

        if (!filters.assignedFilter) {
          filters.assignedFilter = defaultFilter?.filter || 'all_conversations';
          filters.assignedFilterValue = defaultFilter?.id;
        } 
      }

      this.$patch({
        feedsFilters: filters,
        isFetching: true,
      });

      const feedsString = buildGetAllFeedsString(this.feedsFilters);
      const path = await workspaceStore.buildPath(feedsString)

      const [res] = await Promise.all([
        await workspace.get(path, { withCredentials: true }),  
        await this.fetchAllFeedCounts(),
      ])

      const { data } = res;
      const { feeds, contacts, counts, conversations } = data
      const successStorePatch = {
        feeds: sortFeeds(feeds, agentPreferencesStore.pinnedFeeds, this.feedsFilters.order),
        contacts: contacts,
        counts: counts,
        conversations: conversations,
        isFetching: false,
        currentCounts: this.currentCounts,
      };

      if(this.feedsFilters.metadataFilter === 'open' && this.feedsFilters?.assignedFilter === 'all_conversations') {
        successStorePatch.currentCounts = {
          ...successStorePatch.currentCounts,
          all_conversations: feeds.length,
        };
      }

      this.$patch(successStorePatch);
    },

    async assignAgentToSelectedFeeds(agent: AgentDto) {
      const snackbarStore = useSnackbarStore();

      const path = `/agents/${agent.id}/assign`;
      const body = { feeds: this.selectedFeedIds };

      let isSuccess = true;

      await axiosApi.post(path, body, { withCredentials: true }).catch((error) => {
        snackbarStore.handleError(error);
        isSuccess = false;
      });

      if (isSuccess) {
        await this.fetchFeeds()
        this.selectedFeedIds = [];
      }
    },

    async closeSelectedFeeds() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      const path = await workspaceStore.buildPath('feeds/action');
      const body = {
        type: 'Close',
        feeds: this.selectedFeedIds,
      };

      let isSuccess = true;

      await workspace.patch(path, body, { withCredentials: true }).catch((error) => {
        snackbarStore.handleError(error);
        isSuccess = false;
      });

      if (isSuccess) {
        await this.fetchFeeds()
        this.selectedFeedIds = [];
      }
    },

    resortFeeds() {
      const agentPreferencesStore = useAgentPreferencesStore()
      const feedsCopy = [...this.feeds];

      this.feeds = sortFeeds(feedsCopy, agentPreferencesStore.pinnedFeeds, this.feedsFilters.order);
    },

    insertContact(contact: ContactDtoFeed) {
      this.contacts = [...this.contacts, contact];
    },

    insertFeed(feed: Feed) {
      const agentPreferencesStore = useAgentPreferencesStore()
      const feedsCopy = [...this.feeds, feed];

      this.feeds = sortFeeds(feedsCopy, agentPreferencesStore.pinnedFeeds, this.feedsFilters.order);
    },

    updateFeed(feed: Feed) {
      const agentPreferencesStore = useAgentPreferencesStore()
      const feedsCopy = [...this.feeds].map(item => {
        if(item.id === feed.id) {
          item.last_content = feed.last_content
          item.last_message_date = feed.last_message_date
          item.last_feed_event_id = feed.last_feed_event_id
          item.last_interaction_date = feed.last_interaction_date
          item.last_message_status = feed.last_message_status
        }

        return item;
      });

      this.feeds = sortFeeds(feedsCopy, agentPreferencesStore.pinnedFeeds, this.feedsFilters.order);
    },

    async fetchAllFeedCounts() {
      await Promise.all([
        await this.fetchFeedOpenCounts(),
        await this.fetchTeamFeedOpenCounts()
      ])

      this.currentCounts.all_temp = 0
    },

    async fetchFeedOpenCounts() {
      const workspaceStore = useWorkspaceStore();
      const path = await workspaceStore.buildPath('feeds/counts')

      const { data } = await workspace.get(path, { withCredentials: true })  
      const { feedCounts } = data

      this.currentCounts.assigned_to_me = feedCounts.assigned_to_me
      this.currentCounts.unassigned = feedCounts.unassigned
      this.currentCounts.mentioned = feedCounts.mentioned
    },

    async fetchTeamFeedOpenCounts() {
      const workspaceStore = useWorkspaceStore();
      const path = await workspaceStore.buildPath('feeds/counts/teams')

      const { data } = await workspace.get(path, { withCredentials: true })  
      const { teamFeedCounts } = data

      this.currentCounts.assigned_to_team = teamFeedCounts
      const keys = Object.keys(this.currentCounts.assigned_to_team)
      keys.forEach(key => this.currentCounts.all_temp += this.currentCounts.assigned_to_team[key])
    },

    async deleteNote(feedId: string, noteId: string, ): Promise<string | undefined> {
      const workspaceStore = useWorkspaceStore();
      const path = await workspaceStore.buildPath(`feeds/${feedId}/notes/${noteId}`)
      const snackbarStore = useSnackbarStore();
      const response = await workspace.delete(path, { withCredentials: true })
        .catch((error) => {
          snackbarStore.addError('Deleting the note failed', error.message)
        });

      return response?.data?.id;
    },

    async sendMessageByPhoneNumber(body: string, toPhoneNumber: string, fromPhoneNumber: string): Promise<void> {
      const workspaceStore = useWorkspaceStore();
      const payload = { body } as SendMessagePayload

      const path = await workspaceStore.buildPath(`whatsapp/send/${toPhoneNumber}?from=${fromPhoneNumber}`)

      await workspace.post<SendMessageResponse>(path, payload, { withCredentials: true })
    },

    async sendTextMessage(body: string, feedId: string, phoneNumber: string, context?: { message_id: string, body: string }): Promise<HoolaMessage> {
      const workspaceStore = useWorkspaceStore();
      const payload = { body } as SendMessagePayload

      if(context && context.message_id !== '') {
        payload.context = context
      }

      const path = await workspaceStore.buildPath(`whatsapp/send/feed/${feedId}?from=${phoneNumber}`)

      const { data } = await workspace.post<SendMessageResponse>(path, payload, { withCredentials: true })
      this.resetCountForFeedId(feedId)

      const { message } = data

      return message
    },

    async sendTemplateMessage(feedId: string, templateId: string, phoneNumber = ''): Promise<HoolaMessage | undefined> {
      const workspaceStore = useWorkspaceStore();

      const path = await workspaceStore.buildPath(`whatsapp/send/feed/${feedId}/template/${templateId}?from=${phoneNumber}`)
      const { data } = await workspace.post<SendMessageResponse>(path, {}, { withCredentials: true })
     
      this.resetCountForFeedId(feedId)

      return data.message
    },

    async sendReaction(feedId: string, external_message_id: string, emoji: string, phoneNumber: string): Promise<HoolaMessage | undefined> {
      const workspaceStore = useWorkspaceStore();

      const path = await workspaceStore.buildPath(`whatsapp/send/feed/${feedId}/reaction?from=${phoneNumber}`)
      const { data } = await workspace.post<SendMessageResponse>(path, { message_id: external_message_id, emoji: emoji }, { withCredentials: true })

      this.resetCountForFeedId(feedId);
  
      return data.message;
    },

    async sendMedia(feedId: string, url: string, type: string, phoneNumber: string, caption?: string): Promise<HoolaMessage | undefined> {
      const workspaceStore = useWorkspaceStore();
      const path = await workspaceStore.buildPath(`whatsapp/send/feed/${feedId}/media?from=${phoneNumber}`);
      const body = { media: { assetLocation: { url: url }, mimeType: type, caption: caption } };
      const { data } = await workspace.post<SendMessageResponse>(path, body, { withCredentials: true })

      this.resetCountForFeedId(feedId);

      return data.message
    },

    async sendNewTemplate(message: string, language: string, isMarketingTemplate: boolean, to: string, from?: string): Promise<boolean | string> {
      const workspaceStore = useWorkspaceStore();
      const whatsappTemplateStore = useWhatsappTemplateStore();
      const snackbarStore = useSnackbarStore();

      const path = await workspaceStore.buildPath(`whatsapp/send/${to}/template` + (from ? `?from=${from}` : ''));

      whatsappTemplateStore.reset();
      whatsappTemplateStore.currentStateType = 'template';
      whatsappTemplateStore.setText(message);
      whatsappTemplateStore.setLanguage(language);
      whatsappTemplateStore.setCategory(isMarketingTemplate ? 'MARKETING' : 'UTILITY');

      const { object } = await whatsappTemplateStore.create(TemplateOrigin.ONE_OFF);
      const response = await workspace.post(path, { template: object }, { withCredentials: true })
        .catch((error) => {
          snackbarStore.addError('Sending of the template failed', error.message)
        });

      if (!response?.data) {
        return false;
      }

      return true;
    },

    findFeedByPhoneNumber(phoneNumber: string) {
      const cleanedNumber = PhoneNumberUtils.cleanPhone(phoneNumber);
      const feed = this.feeds.find(feed => {
        return isSamePhoneNumber(feed.external_user_id, cleanedNumber);
      });

      return feed;
    },

    async sendTemplateMessageToPhoneNumber(templateId: string, toPhoneNumber: string, fromPhoneNumber = ''): Promise<string | boolean> {
      const workspaceStore = useWorkspaceStore();
      const path = await workspaceStore.buildPath(`whatsapp/send/${toPhoneNumber}/template/${templateId}?from=${fromPhoneNumber}`)
      await workspace.post<SendMessageResponse>(path, { }, { withCredentials: true })
      await this.fetchFeeds();

      const feed = this.findFeedByPhoneNumber(toPhoneNumber);

      if (feed) {
        return feed.id;
      }

      return true;
    },

    async uploadAsset(formData: FormData): Promise<string> {
      const workspaceStore = useWorkspaceStore();
      const path = await workspaceStore.buildPath(`assets/conversations`)

      const { data } = await workspace.post(path, formData, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } })
      const { assetUrl } = data

      return assetUrl
    },

    async takeActionOnFeed(action: FeedAction, feedId: string) {
      const workspaceStore = useWorkspaceStore();
      const id = feedId

      if(action.toLocaleLowerCase().includes('pin') && id) {
        await this.handleFeedPin(id)
      } else {
        const path = await workspaceStore.buildPath(`feeds/${id}/action`)

        await workspace.patch<TakeActionOnFeedResponse>(path, { type: action }, { withCredentials: true })

      }

      if(action === 'Close' || action === 'Open') {
        await this.fetchFeeds()
      }
    },

    async snoozeFeed(feedId: string) {
      const workspaceStore = useWorkspaceStore();

      const id = feedId
      const path = await workspaceStore.buildPath(`feeds/${id}/snooze`)

      await workspace.patch<TakeActionOnFeedResponse>(path, { delay: { 
        unit: 'MINUTES',
        amount: 1,
       } }, { withCredentials: true })

      await this.fetchFeeds()
    },

    findConversationForFeed(feedId: string): FeedWhatsAppConversations | undefined {
      return this.conversations.find(convo => convo.feed_id === feedId)
    },

    isExpiringSoon(feedId: string): boolean | undefined {
      const convo = this.findConversationForFeed(feedId)
      const currentDate = new Date()
    
      if(convo !== undefined && new Date(convo.expires_at) > currentDate) {
        return this.hoursToExpiry(convo) <= 4
      }
    },

    hoursToExpiry(convo: FeedWhatsAppConversations): number {
      const currentDate = new Date()

      return convo ? Math.abs(new Date(convo.expires_at).getTime() - currentDate.getTime()) / 36e5 : -1
    },

    hasExpired(feedId: string): boolean {
      const convoDate = this.getRecentConversationExpiry(feedId)
      const currentDate = new Date()

      if(convoDate) {
        return convoDate.getTime() < currentDate.getTime()
      }

      return false
    },

    getRecentConversationExpiry(feedId: string): Date | undefined {
      const convo = this.findConversationForFeed(feedId)

      if(convo) {
        return new Date(convo.expires_at)
      }
    },

    resetCountForFeedId(feedId: string) {
      this.counts = this.counts.map(count => {
        if(count.feed_id === feedId) {
          count.unreplied_count = 0
        }

        return count
      })
    },

    compareIsPinned(f1: Feed, f2: Feed) {
      const f1Pinned = this.isPinned(f1.id)
      const f2Pinned = this.isPinned(f2.id)
      if (f1Pinned && !f2Pinned) {
        return -1;
      }
      if (f2Pinned && !f1Pinned) {
        return 1;
      }
      return 0;
    },

    isPinned(feedId: string): boolean {
      const agentPreferencesStore = useAgentPreferencesStore()

      return agentPreferencesStore.pinnedFeeds.includes(feedId)
    },

    async handleFeedPin(feedId: string) {
      const agentPreferencesStore = useAgentPreferencesStore()

      const isPinned = agentPreferencesStore.pinnedFeeds.includes(feedId)
      const action = isPinned ? 'unpin' : 'pin'

      await agentPreferencesStore.takeActionOnFeed(feedId, action)

      this.resortFeeds()
    },

    getCount(filter?: AssignedChatFilter, value?: string): number {
      if(!filter) {
        return 1
      }

      const obj = this.currentCounts[filter as AssignedChatFilter]
      let count = 0
  
      if(filter !== 'assigned_to_team') {
          count = obj as number
      } else {
          const teamObj = obj as TeamIdCountMap
          count = teamObj[value!] as number
      }
  
      return count
    },

    findContact(id: string): ContactDtoFeed | undefined {
      return this.contacts.find(contact => contact.id === id)
    },

    setTagsForContact(id: string, tags: ContactTagDto[]) {
      this.contacts = this.contacts.map(c => {
        if(c.id === id) {
          c.tags = tags
        }

        return c
      })
    }
  },
})

export class MessageFilters {
  static DEFAULT_LIMIT = 50
}

export interface MessageFetchResponse {
  count: number
  messages: HoolaMessage[]
}
